<template>
    <styled-interface hide-toolbar>
        <styled-card class="mb-4">
            <template #heading>
                User Settings
            </template>
            <template>
                <v-alert
                    :value="error"
                    type="error"
                    dismissible
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    <span v-html="error" />
                </v-alert>

                <div class="form">
                    <div class="form__userholder">
                        <div class="form__user">
                            <asset-upload
                                :centered-text="true"
                                :centered-title="true"
                                :shortened="true"
                                :initial-image="input.profile_photo"
                                :rounded="true"
                                :records="['user_id']"
                                title="Profile Photo"
                                :types="['jpeg', 'jpg', 'png', 'gif']"
                                @asset-uploaded-url="onProfilePhotoUploaded" />
                        </div>
                    </div>
                    <div class="form__holder">
                        <div class="form__title">
                            <h2>Authentication Details</h2>
                            <div class="form__desc">
                                Important information for your identity in our system.
                            </div>
                        </div>
                        <div class="form__fields auth-details">
                            <div class="auth-details-row">
                                <editable-data-field
                                    label="Full Name"
                                    :value="input.name"
                                    :rules="rules.name"
                                    :handler="onNameChanged" />
                                <editable-data-field
                                    label="Email Address"
                                    :value="input.email"
                                    :rules="rules.email"
                                    :handler="onEmailChanged" />
                                <editable-data-field
                                    label="Password"
                                    value="*******"
                                    :handler="onPasswordChanged">
                                    <template #content>
                                        <v-text-field
                                            v-model="input.current_password"
                                            class="styled-field mb-3"
                                            :rules="rules.password"
                                            type="password"
                                            label="Current Password"
                                            required />
                                        <v-text-field
                                            v-model="input.password"
                                            class="styled-field mb-3"
                                            :rules="rules.password"
                                            type="password"
                                            label="New Password"
                                            required />
                                        <v-text-field
                                            v-model="input.password_confirmation"
                                            class="styled-field mb-3"
                                            :rules="[input.password === input.password_confirmation || 'Password must match.']"
                                            type="password"
                                            label="New Password Confirmation"
                                            required />
                                    </template>
                                </editable-data-field>
                            </div>

                            <div class="auth-details-row">
                                <label class="gray-light-text">2FA (Two-Factor Authentication)</label>

                                <div
                                    v-if="!loading"
                                    class="mt-2 ml-4"
                                    style="display: flex">
                                    <a
                                        v-if="!twoFaConfirmed"
                                        @click="enableTwoFactorAuthentication">
                                        Enable
                                    </a>

                                    <div
                                        v-if="twoFaConfirmed"
                                        style="display: flex">
                                        <p>
                                            2FA is Enabled
                                            <icon
                                                name="check-circle"
                                                color="#7ED321"
                                                class="ml-1"
                                                size="18" />
                                        </p>
                                        <a
                                            class="ml-4 red--text"
                                            @click="showConfirmation">
                                            Disable
                                        </a>
                                    </div>
                                </div>

                                <div v-else>
                                    <icon
                                        name="rotate"
                                        color="#7ED321"
                                        class="ml-1"
                                        size="18" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </styled-card>
        <div>
            <styled-dialog v-model="showConfirmationModal">
                <template #heading>
                    <h3>Disable Two-Factor Authentication</h3>
                </template>

                <template>
                    <p style="font-size: 1.2rem">
                        Disable this security option.
                    </p>
                    <p>
                        This will disable the security option of Two-Factor Authentication. This will make your account more vulnerable. You can re-enable Two-Factor Authentication again if you need.
                    </p>
                </template>

                <template #actions>
                    <a
                        class="mt-2"
                        @click="showConfirmationModal = false">
                        Cancel
                    </a>

                    <div class="form-button">
                        <v-btn
                            style="font-size: 1rem"
                            flat
                            small
                            type="submit"
                            class="blue-btn"
                            color="white"
                            @click="disableTwoFactorAuthentication">
                            YES I WANT TO DISABLE
                        </v-btn>
                    </div>
                </template>
            </styled-dialog>
        </div>
    </styled-interface>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import { requiredRules, emailRules } from '@/helpers/validationRules';
import EditableDataField from '@/components/globals/fields/EditableDataField';
import AssetUpload from '../globals/AssetUpload';
import Icon from '@/components/globals/Icon.vue';
import StyledDialog from '@/components/globals/StyledDialog.vue';
import { TRUSTED_DEVICE_TOKEN } from '@/helpers/storageKeys';

export default {
    name: 'UserSettings',
    title: 'User Settings',
    components: {
        StyledDialog,
        Icon,
        AssetUpload,
        StyledInterface,
        StyledCard,
        EditableDataField
    },
    data() {
        return {
            input: {
                name: '',
                email: '',
                profile_photo: null,
                current_password: '',
                password: '',
                password_confirmation: '',
            },
            error: '',
            twoFactorSecretSet: false,
            twoFaConfirmed: false,
            loading: true,
            rules: {
                name: [ ...requiredRules],
                email: [ ...emailRules, ...requiredRules],
                password: [ ...requiredRules]
            },
            showConfirmationModal: false,
        };
    },
    computed: {
        ...mapGetters([
            'currentUser',
        ])
    },
    watch: {
        currentUser: {
            handler() {
                this.updateTwoFactorStatus();
            },
            deep: true
        },
    },
    activated() {
        this.getUser();
    },
    mounted() {
        this.twoFactorSecretSet = !!this.currentUser.two_factor_secret.value;
        this.getUser();
    },
    created() {
        this.resetInput();
        document.body.classList.add('user-settings-page');
    },
    methods: {
        ...mapActions([
            'getUser',
        ]),
        updateTwoFactorStatus() {
            this.loading = false;
            this.twoFaConfirmed = !!this.currentUser.two_factor_confirmed_at;
        },
        resetInput() {
            this.input = {
                name: this.currentUser.name,
                email: this.currentUser.email,
                profile_photo: this.currentUser.profile_photo,
                current_password: '',
                password: '',
                password_confirmation: '',
            };
        },
        async onNameChanged(data) {
            await this.$http.put('/users/current/update', {
                name: data?.input
            });
            await this.getUser();
            this.resetInput();
        },
        async onEmailChanged(data) {
            await this.$http.put('/users/current/update', {
                email: data?.input
            });
            await this.getUser();
            this.resetInput();
        },
        async onPasswordChanged() {
            await this.$http.put('/users/current/update', this.input);
            await this.getUser();
            this.resetInput();
        },
        async onProfilePhotoUploaded(profilePhotoUrl) {
            await this.$http.put('/users/current/update', {
                profile_photo: profilePhotoUrl
            });
            await this.getUser();
            this.resetInput();
        },
        async enableTwoFactorAuthentication() {
            try {
                await this.$http.post('/user/two-factor-authentication', {
                    'force': true
                });

                await this.$router.push({ name: 'twofa-auth' });

                this.twoFactorSecretSet = true;
            } catch (e) {
                this.error = 'Error! Please try again.';
            }
        },
        showConfirmation() {
            this.showConfirmationModal = true;
        },
        async disableTwoFactorAuthentication() {
            await this.$http.delete('/user/two-factor-authentication', {})
                .then(() => {
                    this.getUser();
                    this.twoFactorSecretSet= false;
                    this.twoFaConfirmed = false;
                    this.showConfirmationModal = false;
                    localStorage.removeItem(TRUSTED_DEVICE_TOKEN);
                })
                .catch(response => {
                    if (response.data.errors) {
                        this.error = response.data.errors;
                    } else {
                        this.error = 'Error! Please try again.';
                    }
                });
        },
    }
};
</script>

<style lang="scss" scoped>
.form {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &__userholder{
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__user{
        width: 250px;
    }
    &__holder{
        width: 70%;
        padding-top: 15px;
    }
    &__fields{
        display: flex;
        justify-content: space-around;
    }
    &__desc{
        margin-bottom: 30px;
        color: #99A6AE;
    }
}
</style>
<style>
.user-settings-page .styled-tooltip{
    width: 100%;
}

.form__fields.auth-details {
    display: flex;
    flex-direction: column;
}

.auth-details-row {
    display: block;
    margin: 2rem;
}

.auth-details-row:first-child{
    display: flex;
    justify-content: space-between;
}
</style>
